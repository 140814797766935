<template>
  <div class="page-dashboard">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <circle-statistic
            :title="$vuetify.lang.t('$vuetify.ticket.many')"
            :caption="
              this.ticketCaption +
                $vuetify.lang.t('$vuetify.ticket.closedPercent')
            "
            icon="list"
            color="success"
            :value="ticketValue"
          />
        </v-col>
      </v-row>
      <AppLoader :dialog.sync="loading"></AppLoader>
    </v-container>
  </div>
</template>

<script>
import Material from 'vuetify/es5/util/colors'
import CircleStatistic from '@/components/widgets/statistic/CircleStatistic'
import AppLoader from '@/components/AppLoader'

export default {
  name: 'PageDashboard',
  components: {
    CircleStatistic,
    AppLoader
  },
  data: () => ({
    color: Material,
    loading: false,
    ticketValue: 0,
    ticketCaption: '',
    statistic: [
      {
        subheading: '',
        headline: '',
        caption: '',
        percent: 0,
        icon: {
          label: 'list',
          color: 'primary'
        },
        linear: {
          value: 0,
          color: 'success'
        }
      }
    ]
  }),
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.menu.dashboard')
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/dashboard',
          {},
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            if (typeof response.data.ticketPercent != 'undefined') {
              this.ticketValue = this.ticketCaption =
                response.data.ticketPercent
              this.ticketCaption = response.data.ticketPercent + '% '
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
